import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './ExpandedRowComponent.scss' ;

const convertToCode = (data) => {
    const jsonString = JSON.stringify(data, null);
    const formattedString = jsonString
        .replace(/": /g, '": "')
        .replace(/",/g, '",<br>')
        .replace(/\n/g, '')
        .replace(/^\[/, '')
        .replace(/\]$/, '');
    return <code dangerouslySetInnerHTML={{ __html: formattedString }}/>;
};

const ExpandedRowComponent = ({ sha256Value, detailValue }) => {
    const parsedSha256Value = convertToCode(sha256Value) || '-';
    const parsedDetailValue = detailValue || '-';
    const { t } = useTranslation();

    return (
        <tr className='expandedRowComponent expandedCellWrapper' >
            <td colSpan={5} className='expandedCell '>
                <table className='customTable'>
                    <tbody>
                        <tr>
                            <td className='customTableCell'>
                                <strong>{t('SHA256')}</strong>
                            </td>
                            <td className='customTableCell'>
                                <strong>{t('Details')}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td className='customTableCell'>{parsedSha256Value}</td>
                            <td className='customTableCell'>{parsedDetailValue}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};

export default ExpandedRowComponent;

ExpandedRowComponent.propTypes = {
    sha256Value: PropTypes.array,
    detailValue: PropTypes.array
};
