import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import { PlainTable } from '@mdc/ui';
import { Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import { generateUniqueTableData, ExpandableRowsComponent } from './ExpandableRowsComponent';
import { tableColumns, iocsData } from './iocsData';
import { UtilsFunctions } from '@mdc/services';

import './IndicatorsOfCompromise.scss';

const IndicatorsOfCompromise = ({ sandboxResults, sandboxResources }) => {

    const { t, ready } = useTranslation();
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [selectExtractedIndex, setSelectExtractedIndex] = useState(null);
    const [selectedDownloadIndex, setSelectedDownloadIndex] = useState(null);
    const handleExpandAll = () => {
        setIsAllExpanded(!isAllExpanded);
    };

    const activeKeys = [
        {
            key: 'iocs',
            label: t('Indicators of Compromise (IOCs)'),
        },
        {
            key: 'extracted',
            label: t('Extracted Files Details'),
        },
        {
            key: 'downloaded',
            label: t('Downloaded Files Details'),
        },
    ];

    const handleButtonExtractedClick = (index) => {
        setSelectExtractedIndex(index);
    };

    const handleButtonDownloadClick = (index) => {
        setSelectedDownloadIndex(index);
    };

    const shouldGenerateDetails = (data) => {
        for (let row of iocsData(t)) {
            if (data[row.value] && data[row.value].length > 0) {
                return true;
            }
        }
        return false;
    };


    const expandableRowsDom = useMemo(() => {
        if (!sandboxResults || !sandboxResources) {
            return;
        }

        return iocsData(t).map((row) => {
            const { uniqueIocs, length } = generateUniqueTableData(sandboxResources[row.value]);

            const plainTableDom = <PlainTable columnsData={tableColumns(t)} data={uniqueIocs} />;
            const title = <div>{row.name}<span className='badge'> {length}</span></div>;

            return sandboxResources[row.value]?.length > 0 && (
                <ExpandableRow
                    key={row?.name}
                    title={title}
                    tableDom={plainTableDom}
                    titleSize='sm'
                    className='mt-5'
                    isExpandedByDefault={isAllExpanded}
                />
            );
        });
    }, [sandboxResults, isAllExpanded]);

    const expandableRowsExtractedDom = useMemo(() => {
        const indicatorsExtracted = sandboxResources?.extractedFiles;
        if (!sandboxResources || !indicatorsExtracted || !indicatorsExtracted.some(shouldGenerateDetails)) {
            return null;
        }

        return (
            <ExpandableRowsComponent
                resources={indicatorsExtracted}
                handleButtonClick={handleButtonExtractedClick}
                selectedIndex={selectExtractedIndex}
                isAllExpanded={isAllExpanded}
            />
        );
    }, [sandboxResources, selectExtractedIndex, isAllExpanded]);

    const expandableRowsDownloadDom = useMemo(() => {
        const indicatorsDownload = sandboxResources?.fileDownloadResults;
        if (!sandboxResources || !indicatorsDownload || !indicatorsDownload.some(shouldGenerateDetails)) {
            return null;
        }

        return (
            <ExpandableRowsComponent
                resources={indicatorsDownload}
                handleButtonClick={handleButtonDownloadClick}
                selectedIndex={selectedDownloadIndex}
                isAllExpanded={isAllExpanded}
            />
        );
    }, [sandboxResources, selectedDownloadIndex, isAllExpanded]);


    const IOCSDom = useMemo(() => {
        if (!expandableRowsDom) {
            if (!expandableRowsExtractedDom) {
                const minRows = 5;
                const maxRows = 12;
                const space = 10;
                const rowHeight = 30;
                const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
                let keyCounter = 0;

                return <ContentLoader
                    className={'contentLoader'}
                    speed={1}
                    height={numRows * (30 + space)}
                    width={'100%'}
                    primaryColor='#EDEEF3'
                    secondaryColor='#f3f3f3'>
                    {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
                </ContentLoader>;
            }
        }
        return (
            <section className='iocs'>
                <Row>
                    <Col xs={12}>
                        <div className='colapseAllButton'>
                            <p className='mt-4'>{t('IOCs (Indicators of Compromise) are extracted from input binaries or derived data, such as extracted files, during the analysis process. Indicators with a strong likelihood of being an IOC are identified and flagged as noteworthy')}{'.'}</p>
                            <Button variant='outline-secondary expandButton' onClick={handleExpandAll}>{isAllExpanded ? t('Collapse All') : t('Expand All')}</Button>
                        </div>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="iocs" id="uncontrolled-tab-example">
                    {activeKeys?.map(({ key, label }) => {
                        if (key === 'extracted' && !expandableRowsExtractedDom || key === 'downloaded' && !expandableRowsDownloadDom) {
                            return;
                        }
                        return (
                            <Tab eventKey={key} title={label} key={key}>
                                {key === 'iocs' && <>{expandableRowsDom}</>}
                                {key === 'extracted' && <>{expandableRowsExtractedDom}</>}
                                {key === 'downloaded' && <>{expandableRowsDownloadDom}</>}
                            </Tab>
                        );
                    })}
                </Tabs>
            </section>
        );

    }, [expandableRowsDom, expandableRowsDownloadDom, expandableRowsExtractedDom]);

    if (!ready) {
        return null;
    }

    return (
        <section className='iocs'>
            <p className='h6Mask mt-5'>{t('Indicators of Compromise (IOCs)')}</p>
            {IOCSDom}
        </section>
    );

};

IndicatorsOfCompromise.propTypes = {
    sandboxResources: PropTypes.object,
    sandboxResults: PropTypes.object
};

export default IndicatorsOfCompromise;

