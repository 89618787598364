import { useEffect, useMemo, useRef, useState } from 'react';
import { generateFileOverviewDetailsData, FileDetailsData } from './FileDetailsData';
import { Row, Col, Tabs, Tab, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableButton from './TableButton';
import FileDetailsSection from './FileDetailsSection';
import ContentLoader from 'react-content-loader';
import { HorizontalTable } from '@mdc/ui';
import EXTRACTED_DATA from '../extracted-files/SandboxExtractedFilesData';
import MultipleTabs from './MultipleTabs';

import './FileDetails.scss';

const FileDetails = ({ sandboxResources, sandboxResults }) => {
    const { t, ready } = useTranslation();
    const rows = 13;
    const space = 10;
    const rowHeight = 30;
    const yOffset = 10;

    const [isModalDisplayed, setIsModalDisplayed] = useState(false);
    const onClose = () => setIsModalDisplayed(!isModalDisplayed);
    const onOpen = () => setIsModalDisplayed(true);
    const [fileOverviewDetails, setFileOverviewDetails] = useState({});
    const [filePreview, setFilePreview] = useState([]);
    const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);
    const [hasClickedBasedOnQueryParam, setHasClickedBasedOnQueryParam] = useState(false);
    const [allExtractedFiles, setAllExtractedFiles] = useState({
        extracted: [],
        downloaded: []
    });

    const activeKeysExtracted = [
        {
            key: 'details',
            label: sandboxResults?.data?.taskReference?.additionalInfo?.submitName
                ? <span className="truncate">{sandboxResults?.data?.taskReference?.additionalInfo?.submitName}</span>
                : sandboxResults?.data?.taskReference?.additionalInfo?.submitName
        },
        {
            key: 'extracted',
            label: t('Extracted Files'),
        },
        {
            key: 'downloaded',
            label: t('Downloaded Files'),
        },
    ];

    const [details, setDetails] = useState([]);
    const buttonRefs = useRef({});
    const [activeKey, setActiveKey] = useState(activeKeysExtracted[0].key);
    const [activeButton, setActiveButton] = useState(null);

    useEffect(() => {
        if (!sandboxResources || Object.keys(sandboxResources).length === 0) {
            return;
        }

        // Set FileOverviewDetails
        const overviewData = generateFileOverviewDetailsData(sandboxResources);
        setFileOverviewDetails((preState) => ({
            ...preState,
            ...overviewData,
        }));

        // Set File Preview
        if (sandboxResources?.fileVisualization?.renderedImages) {
            setFilePreview(sandboxResources?.visualizedSample?.renderedImages);
        }

        const extractedFiles = sandboxResources?.extractedFiles;
        const downloadedFiles = sandboxResources?.fileDownloadResults;

        const extractedFilesTemp = extractedFiles && extractedFiles?.map((data) => {
            return {
                origin: data?.origin?.type,
                size: data?.fileSize,
                type: data?.mediaType?.string,
                FileMagicDescription: data?.extendedData?.fileMagicDescription,
                ssdeep: data?.extendedData?.ssdeep,
                md5: data?.digests?.MD5,
                sha1: data?.digests?.['SHA-1'],
                sha256: data?.digests?.['SHA-256'],
                sha512: data?.digests?.['SHA-512'],
                name: data?.submitName,
                tags: data?.allTags?.map((tag) => tag?.tag?.name),
            };
        });

        const downloadedFilesTemp = downloadedFiles && downloadedFiles.map((data) => {
            return ({
                origin: data?.origin?.type,
                size: data?.fileSize,
                type: data?.mediaType?.string,
                FileMagicDescription: data?.extendedData?.fileMagicDescription,
                md5: data?.digests?.MD5,
                sha1: data?.digests && data?.digests['SHA-1'],
                sha256: data?.digests && data?.digests['SHA-256'],
                sha512: data?.digests && data?.digests['SHA-512'],
                name: data?.digests && data?.digests['SHA-256'],
                tags: data?.allTags?.map((tag) => tag?.tag?.name),
            });
        });

        setAllExtractedFiles((preState) => ({
            ...preState,
            extracted: extractedFilesTemp || [],
            downloaded: downloadedFilesTemp || []
        }));

        const url = new URL(window.location.href);
        const selectValue = url.searchParams.get('select');
        const selectTab = url.searchParams.get('tab');
        if (selectValue && !hasClickedBasedOnQueryParam) {
            if (buttonRefs.current[selectValue]) {
                buttonRefs.current[selectValue].click();
                setHasClickedBasedOnQueryParam(true);
                setActiveKey(activeKeysExtracted[selectTab].key);
            }
        }
    }, [
        sandboxResources,
        window.location.href,
        activeKeysExtracted,
        buttonRefs,
        hasClickedBasedOnQueryParam,
    ]);

    const getProcessedRawDataValue = (value) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        return value;
    };

    const processRawData = (data, meta) =>
        meta
            .filter(
                (row) =>
                    Object.hasOwnProperty.call(data, row.accessor) &&
                    data[row.accessor]
            )
            .map((row) => {
                let value = row.parser
                    ? row.parser(data[row.accessor])
                    : data[row.accessor];
                value = getProcessedRawDataValue(value);
                return {
                    field: row.header,
                    data: value,
                    shouldSanitizeHTML: false,
                };
            });

    const buttonCard = useMemo(() => {
        return (
            <>
                {details && details.length > 0 && (
                    <Card>
                        <Card.Body>
                            <HorizontalTable
                                data={details}
                                className="iconButton horizontalTableRow"
                            />
                        </Card.Body>
                    </Card>
                )}
            </>
        );
    });

    const handleClick = (extractedData) => {
        setDetails(processRawData(extractedData, EXTRACTED_DATA(t)));
        setButtonHasBeenClicked(true);
        setActiveButton(extractedData?.name);
    };

    const expandableTableDOM = useMemo(
        () => (key) => {
            return allExtractedFiles[key]?.map((extractedData) => {
                const shouldBeExpanded = extractedData?.name;
                const isActive = extractedData?.name === activeButton;
                const fileKey = extractedData?.name;

                return (
                    <TableButton
                        ref={(el) =>
                            (buttonRefs.current[extractedData?.name] = el)
                        }
                        title={extractedData?.name}
                        onClick={() => handleClick(extractedData)}
                        shouldBeExpanded={shouldBeExpanded}
                        key={fileKey}
                        isDefaultClicked={
                            fileKey === 0 && !buttonHasBeenClicked
                        }
                        isActive={isActive}
                    />
                );
            });
        },
        [allExtractedFiles, t, buttonHasBeenClicked, activeButton]
    );

    const fileOverviewDetailsDOM = () => {
        if (!fileOverviewDetails || !Object.keys(fileOverviewDetails).length) {
            let keyCounter = 0;
            return (
                <ContentLoader
                    className={'contentLoader'}
                    speed={1}
                    height={rows * (rowHeight + space)}
                    width={'100%'}
                    primaryColor="#EDEEF3"
                    secondaryColor="#f3f3f3"
                >
                    {Array(13)
                        .fill('')
                        .map((_value, index) => (
                            <rect
                                key={keyCounter++}
                                x="0"
                                y={
                                    index === 0
                                        ? yOffset
                                        : index * (rowHeight + space) + yOffset
                                }
                                rx="4"
                                ry="4"
                                width={`${Math.random() * 70 + 30}%`}
                                height={rowHeight}
                            />
                        ))}
                </ContentLoader>
            );
        }

        return <FileDetailsSection
            title={t('Details')}
            data={fileOverviewDetails}
            columnsData={FileDetailsData(t, isModalDisplayed, onClose, onOpen).documentDetails}
        />;
    };

    const activeTabDomExtracted = useMemo(() => {
        return activeKeysExtracted.map((item) => {
            const { key, label } = item;
            let keyCounter = 0;

            if (key === 'extracted' && allExtractedFiles?.extracted?.length > 0) {
                return (
                    <Tab
                        eventKey={key}
                        title={label}
                        key={keyCounter++}
                        className="sandboxDetailFiles"
                    >
                        <Row className="sandboxDetailFiles">
                            {expandableTableDOM(key)}
                        </Row>
                        {buttonCard}
                    </Tab>
                );
            } else if (key === 'details') {
                return (
                    <Tab eventKey={key} title={label} key={keyCounter++}>
                        {fileOverviewDetailsDOM()}
                    </Tab>
                );
            } else if (key === 'downloaded' && allExtractedFiles?.downloaded?.length > 0) {
                return (
                    <Tab
                        eventKey={key}
                        title={label}
                        key={keyCounter++}
                        className="sandboxDetailFiles"
                    >
                        <Row className="sandboxDetailFiles">
                            {expandableTableDOM(key)}
                        </Row>
                        {buttonCard}
                    </Tab>
                );
            }
            return null;
        });
    }, [
        allExtractedFiles,
        expandableTableDOM,
        activeKeysExtracted,
        fileOverviewDetailsDOM,
    ]);

    const filePreviewDOM = useMemo(() => {
        if (!filePreview || filePreview?.length === 0) {
            return;
        }

        return (
            <FileDetailsSection
                title={t('File Preview')}
                subTitle={t('Here you can find a preview of the scanned file')}
                data={filePreview}
                columnsData={
                    FileDetailsData(t, isModalDisplayed, onClose, onOpen)
                        .filePreview
                }
                onlyImages={true}
            />
        );
    }, [filePreview]);

    if (!ready) {
        return;
    }

    return (
        <section className="sandboxFileDetails">
            <Row>
                <Col xs={12}>
                    <p className="h6Mask mt-5">{t('File Details')}</p>
                </Col>
            </Row>
            <Tabs
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                id="sandboxFileTab"
                className="mt-2"
            >
                {activeTabDomExtracted}
            </Tabs>
            <Row>
                <Col xs={12}>
                    <p className="h6Mask mt-5">{t('Extended Details')}</p>
                </Col>
            </Row>
            <MultipleTabs sandboxResources={sandboxResources} />
            <Row>
                <Col xs={12}>{filePreviewDOM}</Col>
            </Row>
        </section>
    );
};

export default FileDetails;

FileDetails.propTypes = {
    sandboxResults: PropTypes.object,
    sandboxResources: PropTypes.object,
    results: PropTypes.object,
    details: PropTypes.object,
};
