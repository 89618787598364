import { useMemo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { dateTimeService } from '@mdc/services';
import { HorizontalTable, CopyButton } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import { UtilsFunctions } from '@mdc/services';

import './OsintLookups.scss';


const OsintLookups = ({ sandboxResources }) => {
    const { t, ready, i18n } = useTranslation();
    const lang = i18n?.language?.split('-')[0] || 'en';
    const getHumanizedDate = (date, t, lang) => dateTimeService.getDateTimeString(date) + ' ' + t('[ {{dateHumanized}} ago ]', { dateHumanized: dateTimeService.dateHumanized(date, lang) });
    const addCopyButton = (data) => <span>{data}<CopyButton icon={'clone'} data={data} /></span>;
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    const getOriginText = (data) => {
        if (data === 'INFORMATIONAL') {
            return 'no threat';
        }
        return capitalizeFirstLetter(data?.split('_')?.join(' ').toLowerCase());
    };
    const convertToCode = (data) => {
        return <span className='font-weight-bold font-italic'>{data}</span>;
    };

    const activeKeys = [
        {
            key: 'MALICIOUS',
            label: t('Malicious')
        },
        {
            key: 'INFORMATIONAL',
            label: t('Informational')
        },
        {
            key: 'UNKNOWN',
            label: t('Unknown')
        }
    ];

    const tableColumns = [
        {
            header: t('Type'),
            accessor: 'type',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('Origin'),
            accessor: 'origin.type',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('Verdict'),
            accessor: 'verdict',
            parser: (data) => getOriginText(data)
        },
        {
            header: t('SHA1'),
            accessor: 'data.file_info.sha1',
            parser: (data) => addCopyButton(data)
        },
        {
            header: t('MD5'),
            accessor: 'data.file_info.md5',
            parser: (data) => addCopyButton(data)
        },
        {
            header: t('Scan date'),
            accessor: 'data.last_start_time',
            parser: (data) => getHumanizedDate(data, t, lang)
        },
        {
            header: t('Malware family'),
            accessor: 'data.malware_family',
            parser: (data) => convertToCode(data)
        },
    ];

    function processRawData(lookup, tableColumns) {
        const processedData = [];

        tableColumns.forEach((column) => {
            const { header, accessor, parser } = column;
            const keys = accessor.split('.');
            let value = lookup;
            for (const key of keys) {
                if (value == null) {
                    value = undefined;
                    break;
                }
                value = value[key];
            }
            if (parser && value && typeof parser === 'function') {
                value = parser(value);
            }
            processedData.push({ field: header, data: value || '-', shouldSanitizeHTML: false });
        });

        return processedData;
    }

    const activeTabDom = useMemo(() => {
        if (!sandboxResources?.results) {
            return;
        }

        return activeKeys
            .map((item) => {
                let keyCounter = 0;
                const tabsContent = sandboxResources?.results?.map((lookup) => {
                    const verdict = lookup.verdict === 'LIKELY_MALICIOUS' ? 'MALICIOUS' : lookup.verdict;

                    return verdict === item.key && <ExpandableRow
                        title={lookup?.resource}
                        tableDom={<HorizontalTable data={processRawData(lookup, tableColumns)} />}
                        titleSize='sm'
                        className='mt-5'
                        {...({
                            extraHeaderFields: [
                                <span key={lookup?.resource} className={`badge ${verdict.toLowerCase()}`}>{verdict.toLowerCase() === 'informational' ? 'no threat' : verdict.toLowerCase()}</span>,
                            ]
                        })}
                        extraHeaderPosition={'end'}
                        key={lookup?.resource}
                    />;
                }).filter(Boolean);
                if (tabsContent.length > 0) {
                    return <Tab eventKey={item.key} title={item.label} key={keyCounter++}>
                        {tabsContent}
                    </Tab>;
                }
                return null;
            })
            .filter(Boolean);
    }, [sandboxResources]);

    const tabsDom = useMemo(() => {
        if (!activeTabDom) {
            const minRows = 5;
            const maxRows = 12;
            const space = 10;
            const rowHeight = 30;
            const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
            let keyCounter = 0;

            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <Tabs
            id="sandboxFileDetailsTab"
            className="mt-2"
        >
            {activeTabDom}
        </Tabs>;
    }, [activeTabDom]);

    if (!ready) {
        return null;
    }

    return <section className='osint'>
        <p className='h6Mask'>{t('Open Source Intelligence Lookups')}</p>
        <p className='mt-4 pb-4'>{t('The input SHA-256 and other selected IOCs are checked against online reputation services for threat detection.')}</p>
        {tabsDom}
    </section>;
};

OsintLookups.propTypes = {
    sandboxResources: PropTypes.object,
    sandboxResults: PropTypes.object
};

export default OsintLookups;
