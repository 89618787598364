export default (t) => {
    return [
        {
            Header: t('Result'),
            accessor: 'result'
        },
        {
            Header: t('Source'),
            accessor: 'source'
        },
        {
            Header: t('Last detected'),
            accessor: 'lastDetected'
        },
        {
            Header: t('Last update'),
            accessor: 'lastUpdate'
        }
    ];
};
