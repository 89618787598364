import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import PropTypes from 'prop-types';
import { PlainTable } from '@mdc/ui';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';
import { tableColumns, iocsData } from './iocsData';

export const generateUniqueTableData = (indicatorData) => {
    let tableData = [];
    let length = 0;

    indicatorData?.forEach((element) => {
        length += element?.references.length;

        element?.references.forEach((ref) => {
            const className = ref.metaData?.isWhitelisted ? 'badge isWhitelisted' : 'badge';
            const verdict = (
                <span className={className}>
                    {ref.metaData?.isWhitelisted ? 'benign' : 'unknown'}
                </span>
            );
            const origin = element.origin?.type?.toLowerCase().replace('_', ' ');

            tableData.push({
                'ioc': ref?.interesting ? <><i className='icon icon-info-circled'></i> {ref.data}</> : <>{ref.data}</>,
                'verdict': verdict,
                'origin': origin
            });
        });
    });

    return {
        uniqueIocs: tableData?.reduce((accumulator, current) => {
            if (!accumulator.find((item) => {
                if (item.ioc === current.ioc && !current.origin.includes(item.origin)) {
                    item.origin += `, ${current.origin}`;
                }
                return item.ioc === current.ioc;
            })) {
                accumulator.push(current);
            }
            return accumulator;
        }, []),
        length
    };
};

export const ExpandableRowsComponent = ({ resources, handleButtonClick, selectedIndex, isAllExpanded }) => {
    const { t } = useTranslation();
    const firstButtonRef = useRef(null);

    const shouldGenerateDetails = (data) => {
        return iocsData(t).some((row) => {
            const { length } = generateUniqueTableData(data[row.value]);
            return length > 0;
        });
    };

    const buttons = resources?.map((data, originalIndex) => ({ data, originalIndex })).filter(({ data }) => shouldGenerateDetails(data)).map(({ data, originalIndex }, mapIndex) => {
        const isActive = selectedIndex === originalIndex;
        const isFirstButton = mapIndex === 0;
        let buttonClass = 'btn';

        if (isActive) {
            buttonClass += ' active';
        } else if (isFirstButton) {
            buttonClass += ' first-button-class';
        }
        const buttonRef = isFirstButton ? firstButtonRef : null;

        return (
            <Button
                key={originalIndex}
                onClick={() => handleButtonClick(originalIndex)}
                className={buttonClass}
                variant='outline-secondary'
                ref={buttonRef}
            >
                {data.digests['SHA-256']}
            </Button>
        );
    });

    useEffect(() => {
        if (firstButtonRef.current) {
            firstButtonRef.current.click();
        }
    }, []);

    const generateDetails = (data) => {
        return iocsData(t).map((row) => {
            const { uniqueIocs, length } = generateUniqueTableData(data[row.value]);
            if (length === 0) {
                return null;
            }

            const plainTableDom = (
                <PlainTable
                    columnsData={tableColumns(t)}
                    data={uniqueIocs}
                />
            );

            const title = (
                <div>
                    {row.name}
                    <span className='badge'>{length}</span>
                </div>
            );

            return (
                <ExpandableRow
                    key={row?.name}
                    title={title}
                    tableDom={plainTableDom}
                    titleSize='sm'
                    className='mt-5'
                    isExpandedByDefault={isAllExpanded}
                />
            );
        });
    };

    return (
        <div>
            {buttons}
            {selectedIndex !== null && selectedIndex < resources.length && generateDetails(resources[selectedIndex])}
        </div>
    );
};

ExpandableRowsComponent.propTypes = {
    resources: PropTypes.array.isRequired,
    handleButtonClick: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    isAllExpanded: PropTypes.bool.isRequired
};

