import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import { getPropertyOf } from './ExtractedStringsData';
import { Checkbox, TextInput } from '@mdc/ui';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import ContentLoader from 'react-content-loader';
import ExpandableMeta from './ExpandableMeta';
import { UtilsFunctions } from '@mdc/services';

import './ExtractedStrings.scss';

const PLACEHOLDER = 'Search string';

const ExtractedStrings = ({ sandboxResources }) => {
    const [allExtractedStrings, setAllExtractedStrings] = useState({
        extracted: [],
        downloaded: []
    });

    const activeKeys = [
        {
            key: 'extracted',
            label: 'Extracted Strings'
        },
        {
            key: 'downloaded',
            label: 'Downloaded Files'
        }
    ];
    const [searchValue, setSearchValue] = useState('');
    const { t, ready } = useTranslation();
    const minRows = 5;
    const maxRows = 12;
    const space = 10;
    const rowHeight = 30;
    const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
    const MAX_LENGTH = 100;
    const [showFullText, setShowFullText] = useState({});
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const toggleShowFullText = (index) => {
        setShowFullText(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleExpandAll = () => {
        setIsAllExpanded(!isAllExpanded);
    };

    const [filters, setFilters] = useState({
        interesting: {
            type: 'key',
            accessor: 'interesting',
            checked: true,
            value: true,
            label: t('Interesting')
        },
        triggeredSignal: {
            type: 'property',
            accessor: 'metaData.triggeredConsumerIDs',
            checked: false,
            label: t('Threat Indicator')
        },
        apiReference: {
            type: 'property',
            accessor: 'metaData.reason',
            checked: false,
            value: 'API_REFERENCE',
            label: t('API Reference')
        },
        UTF8: {
            type: 'property',
            accessor: 'type',
            checked: false,
            value: 'UTF8',
            label: t('UTF8')
        },
    });

    const handleUpdateFilters = (accessor, value) => {
        setFilters((preState) => ({ ...preState, [accessor]: { ...preState[accessor], checked: value } }));
    };

    const searchDOM = useMemo(() => {
        return <TextInput
            onChange={setSearchValue}
            placeholder={PLACEHOLDER}
            initialValue={searchValue}
        />;
    }, []);

    const filterBy = (obj, filtersArray, searchValue) => {
        if (!Array.isArray(obj)) {
            return [];
        }

        if (!filtersArray || filtersArray.length === 0) {
            return obj;
        }

        return obj.filter((item) => {
            const matchesFilter = filtersArray.every((filter) => {
                const objItem = getPropertyOf(item, filters[filter]?.accessor);

                if (filter === 'triggeredSignal') {
                    return item.metaData?.triggeredConsumerIDs !== undefined;
                }

                if (Array.isArray(objItem)) {
                    return objItem.includes(filters[filter]?.value);
                }

                return objItem === filters[filter]?.value;

            });

            if (searchValue.length > 0) {
                const lowerCaseItem = item.str.toLowerCase();
                const lowerCaseSearchValue = searchValue.toLowerCase();
                const startIndex = lowerCaseItem.indexOf(lowerCaseSearchValue);
                return startIndex !== -1 && matchesFilter;
            }

            return matchesFilter;
        });
    };

    const filtersDOM = useMemo(() => {
        return Object.keys(filters)?.map((filter) => {
            return <Checkbox
                isSelected={filters[filter]?.checked}
                onChange={handleUpdateFilters.bind(null, filter, !filters[filter]?.checked)}
                useManualCheck
                key={filter}
            >
                {filters[filter].label}
            </Checkbox>;
        });
    }, [filters]);

    const generateTableData = (strings, searchValue, filters) => {
        return strings.map((origin) => {
            const title = origin?.origin?.type?.replace('_', ' ').toLowerCase();
            const activeFilters = Object.keys(filters).filter((filter) => filters[filter]?.checked === true);
            const filtered = filterBy(origin?.references, activeFilters, searchValue);

            let tableData = filtered.length > 0 ? filtered.map((item, index) => {
                const lowerCaseItem = item.str.toLowerCase();
                const lowerCaseSearchValue = searchValue.toLowerCase();
                const startIndex = lowerCaseItem.indexOf(lowerCaseSearchValue);
                const itemKey = origin.origin?.identifier + '-' + index;
                const meta = item?.metaData;
                if (startIndex !== -1) {
                    const beforeMatch = item.str.substring(0, startIndex);
                    const match = item.str.substring(startIndex, startIndex + searchValue.length);
                    const afterMatch = item.str.substring(startIndex + searchValue.length);

                    return (
                        <Row key={itemKey} className='mt-4 font-weight tableRow meta'>
                            {beforeMatch}
                            <span className='highlighted'>{match}</span>
                            <span>
                                {afterMatch.length > MAX_LENGTH && !showFullText[itemKey] ? (
                                    <>
                                        <span className='afterMatch'>
                                            {afterMatch.substring(0, MAX_LENGTH)}...
                                        </span>
                                        <Button onClick={() => toggleShowFullText(itemKey)} variant='link' className='button'>show more</Button>
                                    </>
                                ) : (
                                    <>
                                        <span className='afterMatch'>
                                            {afterMatch}
                                        </span>
                                        {afterMatch.length > MAX_LENGTH && (
                                            <Button onClick={() => toggleShowFullText(itemKey)} variant='link' className='button'>show less</Button>
                                        )}
                                    </>
                                )}
                            </span>
                            {meta && <ExpandableMeta meta={meta} />}
                        </Row>
                    );
                }
                return null;
            }) : <Row className='mt-4 font-weight tableRow'>No strings matching filter</Row>;

            return (
                <ExpandableRow
                    key={origin?.origin?.identifier}
                    title={<div className='text-capitalize'>{`Origin: ${title}`}</div>}
                    tableDom={<Col className='expandableRow'>{tableData}</Col>}
                    titleSize='sm'
                    className='mt-5 '
                    extraHeaderFields={[`${filtered?.length}/${origin?.references?.length}`]}
                    isExpandedByDefault={isAllExpanded}
                />
            );
        });
    };

    useEffect(() => {
        const downloadedFiles = sandboxResources?.fileDownloadResults;
        const stringsData = downloadedFiles?.flatMap(file => file.strings);
        const extractedData = generateTableData(sandboxResources?.strings || [], searchValue, filters);
        const downloadedData = generateTableData(stringsData || [], searchValue, filters);
        setAllExtractedStrings({
            extracted: extractedData,
            downloaded: downloadedData
        });
    }, [sandboxResources, searchValue, filters, showFullText, isAllExpanded]);

    const activeTabDom = useMemo(() => {
        return activeKeys
            .filter(({ key }) =>
                allExtractedStrings[key] && allExtractedStrings[key].length > 0 && allExtractedStrings[key].some(item => item)
            )
            .map(({ key, label }) => (
                <Tab eventKey={key} title={label} key={key}>
                    <div className="p-3">
                        {allExtractedStrings[key]}
                    </div>
                </Tab>
            ));
    }, [allExtractedStrings, activeKeys]);

    const TabsDom = useMemo(() => {
        if (!sandboxResources?.strings) {
            let keyCounter = 0;
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <Tabs
            id="sandboxFileDetailsTab"
            className="mt-2"
        >
            {activeTabDom}
        </Tabs>;
    }, [activeTabDom]);

    if (!ready) {
        return null;
    }

    return <section className='sandboxExtractedStrings'>
        <Row>
            <Col xs={12}>
                <p className='h6Mask mt-5'>{t('Extracted Strings')}</p>
            </Col>
        </Row>

        <Row>
            <Col xs={12} sm={5} md={6} className='filterInput'>
                {searchDOM}
            </Col>
            <Col xs={12} sm={7} md={6} className='sandboxExtractedStringsFilters mt-sm-0 mt-4'>
                {filtersDOM}
            </Col>
        </Row>
        <Row>
            <Col xs={12} className='mt-5 d-flex justify-content-end'>
                <Button variant='outline-secondary' onClick={handleExpandAll}>{isAllExpanded ? t('Collapse All') : t('Expand All')}</Button>
            </Col>
            <Col xs={12} className='mt-5'>
                {TabsDom}
            </Col>
        </Row>
    </section>;
};

export default ExtractedStrings;

ExtractedStrings.propTypes = {
    sandboxResources: PropTypes.object,
};
