import { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { metadefenderService, localStorage, BackendService, Authenticator, logService, GtmService, i18nUtility } from '@mdc/services';
import { Router } from '@reach/router';
import SideBarPageLayout from '../layouts/sidebar-page/SidebarPageLayout';
import Overview from './overview/Overview';
import DeepCDR from './deep-cdr/DeepCDR';
import Multiscan from './multiscanning/Multiscan';
import Vulnerabilities from './vulnerabilities/Vulnerabilities';
import PeInfo from './peinfo/PeInfo';
import ExtractedFiles from './extracted-files/ExtractedFiles';
import { results, CveStatus, HubspotModal, ConfirmModal, ScrollTopButton, InternalLink } from '@mdc/ui';
import ExifMetadata from './exif-metadata/ExifMetadata';
import ApkData from './apk-data/ApkData';
import Community from './community/Community';
import ScanHistory from './scan-history/ScanHistory';
import ProactiveDLP from './dlp/ProactiveDLP';
import { useTranslation } from 'react-i18next';
import { HUBSPOT_PORTAL_ID, HUBSPOT_FORMS, ROUTES, COOKIE_ALREADY_USED, SCAN_SANITIZED_STATUS } from '@mdc/constants';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie';
import Summary from './sandbox-pages/summary/Summary';
import Network from './sandbox-pages/network/Network';
import IndicatorsOfCompromise from './sandbox-pages/indicators/IndicatorsOfCompromise';
import Processes from './sandbox-pages/processes/Processes';
import SandboxExtractedFiles from './sandbox-pages/extracted-files/SandboxExtractedFiles';
import ExtractedStrings from './sandbox-pages/extracted-strings/ExtractedStrings';
import FileDetails from './sandbox-pages/file-details/FileDetails';
import YaraRules from './sandbox-pages/yara/YaraRules';
import OsintLookups from './sandbox-pages/osint/OsintLookups';
import AddressOverview from './address-overview/AddressOverview';
import AddressLocation from './address-location/AddressLocation';

import './ResultsLayout.scss';
import './dynamic-analysis/DynamicAnalysis.scss';

const ROUTE_PATH = 'results/:type/:id/:action';
const ADDRESS_ROUTE_PATH = 'results/:type/:id';
const EMAIL_ENTERED = 'emailEntered';

const { ResultsHeader, VulnerabilityReportModal } = results;

/* eslint-disable camelcase */
const ResultsLayout = (
    {
        isSignedIn,
        id,
        fileImage,
        scanning,
        progress,
        loading,
        sanitized,
        scanResults,
        peInfoResults,
        exifResults,
        apkInfoResults,
        sandboxResults,
        addressScanResult,
        errorMessage,
        upVotes,
        downVotes,
        setVotes,
        pageName,
        menuItems,
        hideThreatBar,
        addressCategories,
        esKey,
    }) => {
    const { i18n, t, ready } = useTranslation();

    const language = i18nUtility.lookupLang().replace(/-/g, '_');
    const form = HUBSPOT_FORMS[language];

    // Sanitization
    const [showSanitizationForm, setShowSanitizationForm] = useState(false);
    const [emailEntered, setEmailEntered] = useState(undefined);

    // Header state
    const [fileName, setFilename] = useState('');
    const [threatName, setThreatName] = useState('');
    const [fileHash, setFileHash] = useState('');

    // Vote state
    const [disabledVote, setDisabledVote] = useState(true);
    const [upVote, setUpVote] = useState(upVotes);
    const [downVote, setDownVote] = useState(downVotes);
    const [alreadyVoted, setAlreadyVoted] = useState('');

    const [summaryData, setSummaryData] = useState();

    useEffect(() => {
        setUpVote(upVotes);
        setDownVote(downVotes);
    }, [upVotes, downVotes]);

    // Report Vulnerability
    const [showVulnerabilityModal, setShowVulnerabilityModal] = useState(false);
    const [reportVulnerabilityResult, setReportVulnerabilityResult] = useState('');

    // Vulnerabilities
    const [vulnerabilityResults, setVulnerabilityResults] = useState(undefined);

    const [showCollectPointsModal, setShowCollectPointsModal] = useState(false);

    useEffect(() => {
        if (isSignedIn) {
            return;
        }

        (async () => {
            try {
                const apiKey = Authenticator.apikey;
                const [promise] = await BackendService.getGuestSession({ apikey: apiKey });
                const guestSession = await promise;

                if (!guestSession) {
                    return;
                }
                const guestSessionStatus = typeof guestSession?.data?.status === 'string' && guestSession?.data?.status?.split(':');
                if (id === guestSessionStatus[0]) {
                    setShowCollectPointsModal(true);
                }

            } catch (err) {
                // eslint-disable-next-line no-console
                logService.error(err);
            }
        })();
    }, [id, isSignedIn, scanResults]);

    const onLogin = () => {
        Authenticator.authRedirect('/login');
    };

    const onCloseModal = () => {
        setShowCollectPointsModal(false);
    };

    useEffect(() => {
        if (!fileHash) {
            return;
        }

        // Get email entered from localStorage
        setEmailEntered(localStorage.getItem(EMAIL_ENTERED));

        // Sanitization
        setShowSanitizationForm(false);

        // Vote state
        setDisabledVote(true);
        setUpVote(undefined);
        setDownVote(undefined);
        setAlreadyVoted('');

        (async () => {
            // Get my vote
            if (isSignedIn) {
                await getMyVote(fileHash);
            }
        })();
    }, [fileHash]);

    useEffect(() => {
        const sha256 = scanResults?.file_info?.sha256;
        const sha1 = scanResults?.file_info?.sha1;
        const md5 = scanResults?.file_info?.md5;

        // Set result header value
        setFilename(addressScanResult?.address || scanResults?.file_info?.display_name || sha256 || sha1 || md5);
        setThreatName(scanResults?.threat_name);
        setFileHash(sha256 || sha1 || md5);

    }, [scanResults, addressScanResult]);

    // Check vulnerability result
    useEffect(() => {
        if (!scanResults || loading || scanning) {
            setVulnerabilityResults(undefined);
        } else {
            (async () => {
                if (scanResults?.additional_info?.includes('vulnerability')) {
                    const response = await metadefenderService.hash.getAdditional({ hash: scanResults.file_info.sha1, info: 'vulnerability' })[0];
                    setVulnerabilityResults(response?.data?.vulnerability);
                } else {
                    // vulnerability not available
                    setVulnerabilityResults(false);
                }
            })();
        }
    }, [scanResults, loading, scanning]);

    const getMyVote = async (hash) => {
        if (hash) {
            const response = await metadefenderService.hash.getVote({ hash })[0];
            if (response?.data) {
                for (let key in response.data) {
                    if (Object.prototype.hasOwnProperty.call(response.data, key)) {
                        setAlreadyVoted(response.data[key] === 'upvote' ? 'up' : 'down');
                    }
                }

                setDisabledVote(false);
            }
        }
    };

    const handleVote = async (hash, alreadyVoted, isUp, isSignedIn) => {
        if (!isSignedIn) {
            return;
        }

        setDisabledVote(true);
        let response;

        let voteResult = '';
        if ((alreadyVoted === 'up' && isUp) || (alreadyVoted === 'down' && !isUp)) {
            // Delete vote
            response = await metadefenderService.hash.deleteVote({ hash })[0];
        } else {
            if (isUp) {
                voteResult = 'up';
                response = await metadefenderService.hash.saveUpVote({ hash }, { hash })[0];
            } else {
                voteResult = 'down';
                response = await metadefenderService.hash.saveDownVote({ hash }, { hash })[0];
            }
        }

        if (response?.data?.hashVotes) {
            setVotes(response.data.hashVotes);
            setAlreadyVoted(voteResult);

            Cookies.set(COOKIE_ALREADY_USED, true, { secure: true });
        }

        setDisabledVote(false);
    };

    const downloadSanitized = useMemo(() => () => {
        if (sanitized?.file_path && sanitized?.result === SCAN_SANITIZED_STATUS.allowed) {
            if (!isSignedIn && !emailEntered?.sanitization) {
                setShowSanitizationForm(true);
            } else {
                window.open(sanitized.file_path, '_blank'); // eslint-disable-line security/detect-non-literal-fs-filename
            }
        }
    }, [sanitized, emailEntered]);

    const handleFormSanitizationSubmit = () => {
        if (sanitized?.file_path) {
            window.open(sanitized.file_path, '_blank'); // eslint-disable-line security/detect-non-literal-fs-filename

            const email = emailEntered ? emailEntered : {};
            email.sanitization = true;
            localStorage.setItem(EMAIL_ENTERED, email);
            setEmailEntered(email);
        }
    };

    const headerDOM = useMemo(() => {
        return (<ResultsHeader
            fileName={fileName}
            threatName={threatName}
            resultsId={scanResults?.scan_results?.scan_all_result_i}
            sanitizedResult={sanitized}
            downloadSanitized={downloadSanitized}
            loading={loading}
            isSignedIn={isSignedIn}
            upVotes={upVote}
            downVotes={downVote}
            handleUpVote={() => handleVote(fileHash, alreadyVoted, true, isSignedIn)}
            handleDownVote={() => handleVote(fileHash, alreadyVoted, false, isSignedIn)}
            alreadyVoted={alreadyVoted}
            disabledVote={disabledVote && isSignedIn}
            hideThreatBar={hideThreatBar}
            addressCategories={addressCategories}
        />);
    }, [
        loading,
        fileName,
        threatName,
        sanitized,
        downloadSanitized,
        scanResults,
        isSignedIn,
        upVote,
        downVote,
        fileHash,
        alreadyVoted,
        disabledVote,
        hideThreatBar,
        addressCategories
    ]);

    const sandboxResources = useMemo(() => {
        let resourcesObj = {};
        if (!sandboxResults || sandboxResults.status !== 'done' || !sandboxResults.data || !sandboxResults.data.resources) {
            return;
        }

        const resourcesIDs = Object.keys(sandboxResults.data.resources);
        resourcesIDs.forEach((id) => {
            const objId = sandboxResults.data.resources[id];

            Object.keys(objId).forEach((item) => {
                if ((Array.isArray(objId[item]) && objId[item]?.length > 0) || (typeof objId[item] === 'object' && objId[item] !== null)) {
                    if (item === 'results') {
                        // For 'results', concatenate arrays if not empty
                        resourcesObj[item] = resourcesObj[item] ? resourcesObj[item].concat(objId[item]) : objId[item];
                    } else {
                        resourcesObj[item] = objId[item];
                    }
                } else if (!resourcesObj[item]) {
                    // If objId[item] is empty and resourcesObj[item] doesn't exist, assign an empty array
                    resourcesObj[item] = [];
                }
            });
        });

        setSummaryData(sandboxResults);
        return resourcesObj;

    }, [sandboxResults]);


    const subViewDOM = useMemo(() => <Router>
        {i18nUtility.pathList.map((path) => <Fragment key={path}>
            <Overview
                path={`${path}/${ROUTE_PATH}/overview`}
                resultsLoading={loading}
                scanning={scanning}
                results={scanResults}
                sanitizedResult={sanitized}
                downloadSanitized={downloadSanitized}
                sandboxResults={sandboxResults}
                vulnerabilityResults={vulnerabilityResults}
                upVote={upVote}
                downVote={downVote}
                progress={progress}
                fileImage={fileImage}
                reportVulnerability={() => setShowVulnerabilityModal(true)}
                reportVulnerabilityResult={reportVulnerabilityResult}
                isSignIn={isSignedIn}
                peInfoResults={peInfoResults}
                esKey={esKey}
            />

            <Multiscan
                path={`${path}/${ROUTE_PATH}/multiscan`}
                results={scanResults}
                resultsLoading={loading}
                scanning={scanning}
                progress={progress}
                fileImage={fileImage}
            />

            <PeInfo
                path={`${path}/${ROUTE_PATH}/peinfo`}
                results={scanResults}
                resultsLoading={loading}
                scanning={scanning}
                progress={progress}
                fileImage={fileImage}
                peInfoResults={peInfoResults}
            />

            <DeepCDR
                path={`${path}/${ROUTE_PATH}/sanitization`}
                results={scanResults}
                resultsLoading={loading}
                sanitizedResult={sanitized}
                downloadSanitized={downloadSanitized}
                scanning={scanning}
                fileImage={fileImage}
            />

            <Vulnerabilities
                path={`${path}/${ROUTE_PATH}/vulnerabilities`}
                results={scanResults}
                vulnerabilityResults={vulnerabilityResults}
                resultsLoading={loading}
                scanning={scanning}
                reportVulnerability={() => setShowVulnerabilityModal(true)}
                reportVulnerabilityResult={reportVulnerabilityResult}
                isSignedIn={isSignedIn}
            />

            <ExtractedFiles
                path={`${path}/${ROUTE_PATH}/extracted`}
                results={scanResults}
                resultsLoading={loading}
                scanning={scanning}
                progress={progress}
                fileImage={fileImage}
            />

            <ApkData
                path={`${path}/${ROUTE_PATH}/apk-data`}
                apkInfoResults={apkInfoResults}
                scanning={scanning}
                resultsLoading={loading}
            />

            <ExifMetadata
                path={`${path}/${ROUTE_PATH}/exif-metadata`}
                scanning={scanning}
                resultsLoading={loading}
                exifResults={exifResults}
            />

            <ProactiveDLP
                path={`${path}/${ROUTE_PATH}/proactive-dlp`}
                results={scanResults}
                resultsLoading={loading}
                scanning={scanning}
                progress={progress}
                fileImage={fileImage}
            />

            <ScanHistory
                path={`${path}/${ROUTE_PATH}/history`}
                results={scanResults}
                resultsLoading={loading}
                scanning={scanning}
                progress={progress}
                fileImage={fileImage}
            />

            <Community
                path={`${path}/${ROUTE_PATH}/community`}
                resultsLoading={loading}
                scanning={scanning}
                upVotes={upVotes}
                downVotes={downVotes}
                handleUpVote={() => handleVote(fileHash, alreadyVoted, true, isSignedIn)}
                handleDownVote={() => handleVote(fileHash, alreadyVoted, false, isSignedIn)}
                alreadyVoted={alreadyVoted}
                disabledVote={disabledVote && isSignedIn}
                isSignedIn={isSignedIn}
                fileImage={fileImage}
            />

            <Summary
                path={`${path}/${ROUTE_PATH}/sandbox/summary`}
                resultsLoading={loading}
                scanning={scanning}
                progress={progress}
                fileImage={fileImage}
                sandboxResults={summaryData}
            />

            <Network
                path={`${path}/${ROUTE_PATH}/sandbox/network`}
                resultsLoading={loading}
                scanning={scanning}
                sandboxResults={sandboxResults}
                sandboxResources={sandboxResources}
                fileImage={fileImage}
            />

            <IndicatorsOfCompromise
                path={`${path}/${ROUTE_PATH}/sandbox/indicators`}
                sandboxResults={sandboxResults}
                sandboxResources={sandboxResources}
            />

            <YaraRules
                path={`${path}/${ROUTE_PATH}/sandbox/yara`}
                sandboxResults={sandboxResults}
                sandboxResources={sandboxResources}
            />

            <Processes
                path={`${path}/${ROUTE_PATH}/sandbox/processes`}
                resultsLoading={loading}
                scanning={scanning}
                sandboxResults={sandboxResults}
                sandboxResources={sandboxResources}
                fileImage={fileImage}
            />

            <SandboxExtractedFiles
                path={`${path}/${ROUTE_PATH}/sandbox/extracted`}
                resultsLoading={loading}
                scanning={scanning}
                fileImage={fileImage}
                sandboxResources={sandboxResources}
                isSignedIn={isSignedIn}
                sandboxResults={sandboxResults}
                results={scanResults}

            />

            <ExtractedStrings
                path={`${path}/${ROUTE_PATH}/sandbox/extracted-strings`}
                resultsLoading={loading}
                scanning={scanning}
                sandboxResults={sandboxResults}
                fileImage={fileImage}
                sandboxResources={sandboxResources}
                isSignedIn={isSignedIn}
            />

            <FileDetails
                path={`${path}/${ROUTE_PATH}/sandbox/details`}
                resultsLoading={loading}
                scanning={scanning}
                sandboxResults={sandboxResults}
                fileImage={fileImage}
                sandboxResources={sandboxResources}
                isSignedIn={isSignedIn}
            />

            <OsintLookups
                path={`${path}/${ROUTE_PATH}/sandbox/osint`}
                sandboxResources={sandboxResources}
            />


            <AddressOverview
                path={`${path}/${ADDRESS_ROUTE_PATH}/overview`}
                resultsLoading={loading}
                addressScanResult={addressScanResult}
                fileImage={fileImage}
            />

            <AddressLocation
                path={`${path}/${ADDRESS_ROUTE_PATH}/location`}
                resultsLoading={loading}
                addressScanResult={addressScanResult}
            />
        </Fragment>)}
    </Router>, [
        loading,
        scanning,
        scanResults,
        sanitized,
        downloadSanitized,
        sandboxResults,
        peInfoResults,
        exifResults,
        addressScanResult,
        upVote,
        downVote,
        progress,
        fileImage,
        vulnerabilityResults,
        reportVulnerabilityResult,
        disabledVote,
        summaryData,
        i18n
    ]);

    const breadcrumb = useMemo(() => {
        return <div className='breadcrumbs'>
            {sanitized?.original_data_id && <InternalLink to={`${ROUTES.results.href}${ROUTES.results.file}/${sanitized?.original_data_id}/regular/overview`}>{t('Original file')}</InternalLink>}
            {scanResults?.parent_data_id && <InternalLink to={`${ROUTES.results.href}${ROUTES.results.file}/${scanResults?.parent_data_id}/regular/extracted`} state={{ isRedirected: true }}>{t('Parent archive')}</InternalLink>}
        </div>;
    }, [sanitized, scanResults, t]);

    const bodyDOM = useMemo(() => {
        if (errorMessage) {
            return <CveStatus message={errorMessage} isUnderAnalysis={false} />;
        }

        return <>
            {breadcrumb}
            {headerDOM}
            {subViewDOM}
        </>;
    }, [headerDOM, subViewDOM, errorMessage, breadcrumb]);

    const onReportVulnerabilitySuccess = (result) => {
        setShowVulnerabilityModal(false);
        setReportVulnerabilityResult(result);
        GtmService.initialize({
            event: 'VulnerabilitiesReport',
            title: 'Click on Submit Vulnerability Button',
            result: result
        });
    };

    const vulnerabilityReportModal = useMemo(() => {
        if (!scanResults) {
            return null;
        }

        const hash = scanResults?.file_info?.sha1 || scanResults?.file_info?.md5 || scanResults?.file_info?.sha256;

        return <VulnerabilityReportModal
            hash={hash}
            fileName={scanResults?.file_info?.display_name || hash}
            show={showVulnerabilityModal}
            onClose={() => setShowVulnerabilityModal(false)}
            successCallback={onReportVulnerabilitySuccess}
        />;

    }, [scanResults, showVulnerabilityModal]);

    if (!ready) {
        return null;
    }

    return <>
        <SideBarPageLayout menuItems={menuItems} pageName={pageName}>
            <>
                <Helmet>
                    <script
                        src='https://code.jquery.com/jquery-2.2.4.min.js'
                        integrity='sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44='
                        crossOrigin='anonymous' />
                </Helmet>

                <section className='resultsLayout'>
                    {bodyDOM}
                    {vulnerabilityReportModal}

                    <HubspotModal
                        portalId={HUBSPOT_PORTAL_ID}
                        formId={form.SANITIZATION_FORM_ID}
                        handleClose={() => setShowSanitizationForm(false)}
                        handleSubmit={handleFormSanitizationSubmit}
                        show={showSanitizationForm}
                        title={t('Please enter your information to download the sanitized file')}
                    />

                    <ConfirmModal
                        className='advancedOptionsModal'
                        show={showCollectPointsModal}
                        onAccept={onLogin}
                        onCancel={onCloseModal}
                        title={t('Sign in to collect points')}
                        // eslint-disable-next-line no-useless-escape
                        body={<p dangerouslySetInnerHTML={{ __html: t('Thank you for uploading this file. <a href=\"/login\">Sign in</a> and gain reputation points for it, and participate in our <a href=\"/leaderboard\">leaderboard</a>') }}></p>}
                        acceptVariant='primary'
                        acceptText={t('Sign In')}
                        cancelText={t('Cancel')}
                    />
                </section>
            </>
        </SideBarPageLayout>
        <ScrollTopButton />

    </>;
};

ResultsLayout.propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    fileImage: PropTypes.object.isRequired,
    pageName: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
    id: PropTypes.string,
    scanResults: PropTypes.object,
    peInfoResults: PropTypes.object,
    exifResults: PropTypes.object,
    apkInfoResults: PropTypes.object,
    sandboxResults: PropTypes.object,
    sanitized: PropTypes.any,
    addressScanResult: PropTypes.object,
    scanning: PropTypes.string,
    progress: PropTypes.number,
    errorMessage: PropTypes.string,
    upVotes: PropTypes.number,
    downVotes: PropTypes.number,
    loading: PropTypes.bool,
    setVotes: PropTypes.func,
    hideThreatBar: PropTypes.bool,
    addressCategories: PropTypes.any,
    esKey: PropTypes.string,
};

export default ResultsLayout;
