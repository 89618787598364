import { CopyButton, TextModal } from '@mdc/ui';

const addCopyButton = (data) => <span>{data}<CopyButton icon={'clone'} data={data} /></span>;
const convertObjectToHumanReadable = (data) => {
    if (!data) {
        return;
    }

    if (Array.isArray(data)) {
        return data.map((item) => {
            return <span className='tag' key={item?.id}>{item}</span>;
        });
    }

    return Object.keys(data).map((item) => {
        if (item === '0') {
            return '-';
        }
        return <p key={item?.id} style={{ lineHeight: '25px' }}>
            <strong>{item}{':'}</strong>
            {' '}
            {data[item]}
        </p>;
    });
};

const convertToCode = (data) => {
    return <code dangerouslySetInnerHTML={{
        __html: JSON.stringify(data, null, 6)?.replace(/\n( *)/g, (match, p) => {
            return '<br>' + '&nbsp;'.repeat(p.length);
        })
    }} />;
};

const boolParser = (data) => {
    return data ? <span className='boolean true'>true</span> : <span className='boolean false'>false</span>;
};

const convertBase64ToImage = (t, data, isModalDisplayed, onCloseModal, handleShowModal) => {
    if (!data || data === '-') {
        return;
    }

    const imgDOM = <img alt='Visualization' src={`data:image/png;base64,${data}`} className='noBorder' title='Visualization' />;

    return <>
        <img alt='VisualizationModal' src={`data:image/png;base64,${data}`} onClick={handleShowModal} title='Visualization Modal' />
        <TextModal
            show={isModalDisplayed}
            onClose={onCloseModal}
            title={t('File Visualization')}
            body={[{ value: imgDOM }]}
            className='sandboxCustomImageModal'
            size={'lg'}
            key={data}
        />
    </>;
};

const convertBytes = (bytes, decimals = 2) => {
    if (!+bytes) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const generateFileOverviewDetailsData = ({ fileSize, extendedData, digests, visualizedSample }) => {
    return ({
        fileMagicDescription: extendedData?.fileMagicDescription || '-',
        fileSize: fileSize || '-',
        md5: digests && digests['MD5'] || '-',
        sha1: digests && digests['SHA-1'] || '-',
        sha256: digests && digests['SHA-256'] || '-',
        sha512: digests && digests['SHA-512'] || '-',
        ssdeep: extendedData?.ssdeep || '-',
        vbaStomping: extendedData?.vbaStomping,
        visualization: visualizedSample?.base64 || null,
        architecture: extendedData?.architecture || '-',
        isDotNet: extendedData?.isDotNet,
        isPacked: extendedData?.isPacked,
        isDigitallySigned: extendedData?.isDigitallySigned,
        content: ''
    });
};

const getValue = (key, metaData, defaultValue = '-') => {
    if (key.includes(':')) {
        return metaData && metaData[key] || defaultValue;
    }
    return metaData?.[key] || defaultValue;
};

const getMeta = (metaData) => {
    return {
        appVersion: getValue('AppVersion', metaData),
        application: getValue('Application', metaData),
        company: getValue('Company', metaData),
        contentType: getValue('Content-Type', metaData),
        docSecurityString: getValue('DocSecurityString', metaData),
        template: getValue('Template', metaData),
        cpRevision: getValue('cp:revision', metaData),
        dcPublisher: getValue('dc:publisher', metaData),
        dcTermsCreated: getValue('dcterms:created', metaData),
        dcTermsModified: getValue('dcterms:modified', metaData),
        entropy: getValue('entropy', metaData),
        fileModifiedTimestamps: getValue('fileModifiedTimestamps', metaData),
        isRepaired: getValue('isRepaired', metaData),
        languageCodes: getValue('languageCodes', metaData),
        characterCount: getValue('meta:character-count', metaData),
        characterCountWithSpaces: getValue('meta:character-count-with-spaces', metaData),
        lineCount: getValue('meta:line-count', metaData),
        pageCount: getValue('meta:page-count', metaData),
        paragraphCount: getValue('meta:paragraph-count', metaData),
        wordCount: getValue('meta:word-count', metaData),
        xmtpNPages: getValue('xmpTPg:NPages', metaData),
    };
};

export const generateFileExtendedDetailsData = (sandboxResources) => {
    const { extendedData, metaData } = sandboxResources;
    let richHeader = {};
    if (extendedData?.richHeader) {
        Object.assign(richHeader, extendedData.richHeader);
    }
    if (extendedData?.richHeaderEx) {
        Object.assign(richHeader, extendedData.richHeaderEx);
    }
    const characteristics = { fileCharacteristicsReadable: extendedData?.fileCharacteristicsReadable, dllCharacteristicsReadable: extendedData?.dllCharacteristicsReadable, fileCharacteristics: extendedData?.fileCharacteristics, dllCharacteristics: extendedData?.dllCharacteristics };
    const header = {
        imageBase: extendedData?.imageBase || '-',
        entrypointName: extendedData?.entrypointName || '-',
        entrypointEntropy: extendedData?.entrypointEntropy || '-',
        entrypointVA: extendedData?.entrypointVA || '-',
        compilerFlags: extendedData?.compilerFlags || '-',
        pointerToSymbolTable: extendedData?.pointerToSymbolTable || '-',
        numberOfSymbols: extendedData?.numberOfSymbols || '-',
        crcInFile: extendedData?.crcInFile || '-',
        crcActual: extendedData?.crcActual || '-',
        linkerVersionMajor: extendedData?.linkerVersionMajor || '-',
        linkerVersionMinor: extendedData?.linkerVersionMinor || '-'
    };

    return ({
        meta: getMeta(metaData),
        oleStreams: extendedData?.streams,
        importsEx: extendedData?.importsEx,
        certInfos: sandboxResources?.certInfos,
        resources: extendedData?.resources,
        sections: extendedData?.sections,
        verinfo: extendedData?.verinfo,
        richHeader: richHeader,
        pdbData: extendedData?.pdbData,
        characteristics: characteristics,
        header: header,
    });
};

export const FileDetailsData = (t, isModalDisplayed, onCloseModal, handleShowModal) => {
    return {
        documentDetails: [
            {
                header: t('FileMagicDescription'),
                accessor: 'fileMagicDescription',
            },
            {
                header: t('Size'),
                accessor: 'fileSize',
                parser: (data) => convertBytes(data)
            },
            {
                header: t('MD5'),
                accessor: 'md5',
                parser: (data) => addCopyButton(data)
            },
            {
                header: t('SHA1'),
                accessor: 'sha1',
                parser: (data) => addCopyButton(data)
            },
            {
                header: t('SHA256'),
                accessor: 'sha256',
                parser: (data) => addCopyButton(data)
            },
            {
                header: t('SHA512'),
                accessor: 'sha512',
                parser: (data) => addCopyButton(data)
            },
            {
                header: t('Ssdeep'),
                accessor: 'ssdeep',
                parser: (data) => addCopyButton(data)
            },
            {
                header: t('Architecture'),
                accessor: 'architecture',
            },
            {
                header: t('Vba Stomping'),
                accessor: 'vbaStomping',
                parser: (data) => boolParser(data)
            },
            {
                header: t('Is DotNet'),
                accessor: 'isDotNet',
                parser: (data) => boolParser(data)
            },
            {
                header: t('Is Packed'),
                accessor: 'isPacked',
                parser: (data) => boolParser(data)
            },
            {
                header: t('Is Digitally Signed'),
                accessor: 'isDigitallySigned',
                parser: (data) => boolParser(data)
            },
            {
                header: t('Visualization'),
                accessor: 'visualization',
                parser: (data) => data && convertBase64ToImage(t, data, isModalDisplayed, onCloseModal, handleShowModal)
            },
        ],

        extendedDetails: {
            meta: [
                {
                    header: t('AppVersion'),
                    accessor: 'appVersion',
                },
                {
                    header: t('Application'),
                    accessor: 'application',
                },
                {
                    header: t('Company'),
                    accessor: 'company',
                },
                {
                    header: t('Content-Type'),
                    accessor: 'contentType',
                },
                {
                    header: t('DocSecurityString'),
                    accessor: 'docSecurityString',
                },
                {
                    header: t('Template'),
                    accessor: 'template',
                },
                {
                    header: t('Cp:revision'),
                    accessor: 'cpRevision',
                },
                {
                    header: t('Dc:publisher'),
                    accessor: 'dcPublisher',
                },
                {
                    header: t('Dcterms:created'),
                    accessor: 'dcTermsCreated',
                },
                {
                    header: t('Dcterms:modified'),
                    accessor: 'dcTermsModified',
                },
                {
                    header: t('Entropy'),
                    accessor: 'entropy',
                },
                {
                    header: t('FileModifiedTimestamps'),
                    accessor: 'fileModifiedTimestamps',
                    parser: (data) => convertObjectToHumanReadable(data)
                },
                {
                    header: t('IsRepaired'),
                    accessor: 'isRepaired',
                    parser: (data) => data.isRepaired ? 'True' : 'False'
                },
                {
                    header: t('LanguageCodes'),
                    accessor: 'languageCodes',
                    parser: (data) => convertObjectToHumanReadable(data)
                },
                {
                    header: t('Meta:character-count'),
                    accessor: 'characterCount',
                },
                {
                    header: t('Meta:character-count-with-spaces'),
                    accessor: 'characterCountWithSpaces',
                },
                {
                    header: t('Meta:line-count'),
                    accessor: 'lineCount',
                },
                {
                    header: t('Meta:page-count'),
                    accessor: 'pageCount',
                },
                {
                    header: t('Meta:paragraph-count'),
                    accessor: 'paragraphCount',
                },
                {
                    header: t('Meta:word-count'),
                    accessor: 'wordCount',
                },
                {
                    header: t('XmTPg:NPages'),
                    accessor: 'xmtpNPages',
                }
            ],

            oleStreams: [
                {
                    header: t('Filename'),
                    accessor: 'filename',
                },
                {
                    header: t('VbaFilename'),
                    accessor: 'vbaFilename',
                },
                {
                    header: t('VbaCode'),
                    accessor: 'vbaCode',
                    parser: (data) => convertToCode(data)
                },

            ],

            importsEx: [
                {
                    Header: t('Name'),
                    accessor: 'name',
                },
                {
                    Header: t('File Rva'),
                    accessor: 'fileRva',
                },
                {
                    Header: t('Suspicious'),
                    accessor: 'suspicious',
                },
                {
                    Header: t('Ordinal'),
                    accessor: 'ordinal',
                },
                {
                    Header: t('MITRE (tactic - technique)'),
                    accessor: 'allMitreTechniquesAsString',
                }
            ],

            certInfos: [
                {
                    header: t('Owner'),
                    accessor: 'owner',
                },
                {
                    header: t('Issuer'),
                    accessor: 'issuer',
                },
                {
                    header: t('Serial Number'),
                    accessor: 'serialNumber',
                },
                {
                    header: t('Valid From'),
                    accessor: 'validFrom',
                },
                {
                    header: t('Valid Until'),
                    accessor: 'validUntil',
                },
                {
                    header: t('MD5'),
                    accessor: 'MD5',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('SHA1'),
                    accessor: 'SHA1',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('IsExpired'),
                    accessor: 'isExpired',
                    parser: (data) => boolParser(data)
                },
                {
                    header: t('IsRevoked'),
                    accessor: 'isRevoked',
                    parser: (data) => boolParser(data)
                },
                {
                    header: t('IsSelfSigned'),
                    accessor: 'isSelfSigned',
                    parser: (data) => boolParser(data)
                },
                {
                    header: t('Public Key'),
                    accessor: 'PublicKey',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Public Key Algorithm'),
                    accessor: 'PublicKeyAlgorithm',

                },
            ],
            resources: [
                {
                    header: t('Type'),
                    accessor: 'type',
                },
                {
                    header: t('Id'),
                    accessor: 'id',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('RVA'),
                    accessor: 'rva',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('File RVA'),
                    accessor: 'fileRva', parser: (data) => convertToCode(data)

                },
                {
                    header: t('File Magic Description'),
                    accessor: 'fileMagicDescription',
                },
                {
                    header: t('Language'),
                    accessor: 'lang',
                },
                {
                    header: t('Sub Language'),
                    accessor: 'sublang',
                },
                {
                    header: t('Size'),
                    accessor: 'size',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Sha256'),
                    accessor: 'sha256',
                    parser: (data) => convertToCode(data)
                }
            ],
            sections: [
                {
                    header: t('Name'),
                    accessor: 'name',
                },
                {
                    header: t('Entropy'),
                    accessor: 'entropy',
                },
                {
                    header: t('File RVA'),
                    accessor: 'fileRva',
                    parser: (data) => convertToCode(data)

                },
                {
                    header: t('VirtualSize'),
                    accessor: 'virtualSize',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('SizeOfRawData'),
                    accessor: 'sizeOfRawData',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('MD5'),
                    accessor: 'MD5',
                },
                {
                    header: t('Characteristics'),
                    accessor: 'characteristics',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('CharacteristicsReadable'),
                    accessor: 'characteristicsReadable',
                    parser: (data) => convertToCode(data)
                },
            ],

            verinfo: [
                {
                    header: t('Company Name'),
                    accessor: 'CompanyName',
                },
                {
                    header: t('File Description'),
                    accessor: 'FileDescription',

                },
                {
                    header: t('File Version'),
                    accessor: 'FileVersion',
                },
                {
                    header: t('Internal Name'),
                    accessor: 'InternalName',
                },
                {
                    header: t('Legal Copyright'),
                    accessor: 'LegalCopyright',
                },
                {
                    header: t('Original Filename'),
                    accessor: 'OriginalFilename',
                },
                {
                    header: t('Product Name'),
                    accessor: 'ProductName',
                },
                {
                    header: t('Product Version'),
                    accessor: 'ProductVersion',
                },
            ],

            richHeader: [
                {
                    header: t('Checksum'),
                    accessor: 'checksum',
                },
                {
                    header: t('ClearData'),
                    accessor: 'clearData',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Csum calc'),
                    accessor: 'csum_calc',
                    parser: (data) => convertToCode(data)

                },
                {
                    header: t('Csum file'),
                    accessor: 'csum_file',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Key'),
                    accessor: 'key',
                },
                {
                    header: t('Offset'),
                    accessor: 'offset',
                },
                {
                    header: t('Raw Data'),
                    accessor: 'rawData',
                    parser: (data) => convertToCode(data)
                }
            ],

            pdbData: [
                {
                    header: t('Age'),
                    accessor: 'age',
                },
                {
                    header: t('Pdb Path'),
                    accessor: 'pdbPath',
                },
                {
                    header: t('Size'),
                    accessor: 'size',
                },
                {
                    header: t('GuidData1'),
                    accessor: 'guidData1',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('GuidData2'),
                    accessor: 'guidData2',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('GuidData3'),
                    accessor: 'guidData3',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('GuidData4'),
                    accessor: 'guidData4',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Timestamp'),
                    accessor: 'timestamp',
                },
            ],

            characteristics: [
                {
                    header: t('Dll Characteristics'),
                    accessor: 'dllCharacteristics',
                },
                {
                    header: t('File Characteristics'),
                    accessor: 'fileCharacteristics',
                },
                {
                    header: t('Dll Characteristics Readable'),
                    accessor: 'dllCharacteristicsReadable',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('File Characteristics Readable'),
                    accessor: 'fileCharacteristicsReadable',
                    parser: (data) => convertToCode(data)
                }
            ],

            header: [
                {
                    header: t('Image Base'),
                    accessor: 'imageBase',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Entrypoint Name'),
                    accessor: 'entrypointName',
                },
                {
                    header: t('Entrypoint Entropy'),
                    accessor: 'entrypointEntropy',
                },
                {
                    header: t('Entrypoint VA'),
                    accessor: 'entrypointVA',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Crc In File'),
                    accessor: 'crcInFile',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Crc Actual'),
                    accessor: 'crcActual',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Linker Version Major'),
                    accessor: 'linkerVersionMajor',
                },
                {
                    header: t('Linker Version Minor'),
                    accessor: 'linkerVersionMinor',
                },
                {
                    header: t('Compiler Flags'),
                    accessor: 'compilerFlags',
                    parser: (data) => convertToCode(data)
                },
                {
                    header: t('Pointer To Symbol Table'),
                    accessor: 'pointerToSymbolTable',
                },
                {
                    header: t('Number Of Symbols'),
                    accessor: 'numberOfSymbols',
                }

            ]

        },
    };
};
